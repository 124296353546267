import * as React from "react"

import PickerScreen from '../../../../../components/pickerScreen'


const Picker = ({ data }) => (
  <PickerScreen
    question="What are you starting from?"
    options={[
      {
        label: "I am starting from scratch",
        link: "/app/digital-product-design/lo-fi/design-from-scratch/"
      },
      {
        label: "I have some wireframe sketches",
        link: "/app/digital-product-design/lo-fi/design-from-wireframes/"
      }
  ]}/>
)

export default Picker;
